<template>
  <van-popup v-model="show" position="bottom" :style="{ height: '68%' }" round>
    <div class="keyParamPopup">
      <div class="title titleBottom">关键参数</div>
      <div class="main">
        <div v-for="item in keyList" class="item" :key="item.key">
          <div class="left">{{ item.name }}</div>
          <div class="tight">{{ detailsData[item.key] }}</div>
        </div>
      </div>
    </div>
  </van-popup>
</template>

<script>
export default {
  props: {
    detailsData:{
      type: Object,
      default: () => {
        return {};
      },
    },
    keyList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      show: false,
    };
  },
  created() {},
  methods: {
    showPopuo() {
      this.show = true;
    },
  },
};
</script>
<style lang='scss' scoped>
@import "@styles/variables.scss";
.keyParamPopup {
  padding: 22px 24px;
  .title {
    font-size: 16px;
    font-weight: 500;
    color: $color1;
    padding-bottom: 12px;
  }
  .titleBottom {
    border-bottom: 1px solid rgba($color: #000000, $alpha: 0.09);
  }
  .main {
    padding-top:8px;
    .item {
        font-size: 14px;
        color: $color1;
        display: flex;
        justify-content: space-between;
        line-height: 24px;
        .left{
            color: $color3;
        }
    }
  }
}
</style>