<template>
  <van-popup v-model="show" position="bottom" :style="{ height: '100%' }">
    <div class="protocolPopup">
      <div class="mian">
        123
      </div>
      <div class="footer" @click="showPopuo">确定</div>
    </div>
  </van-popup>
</template>

<script>
export default {
  data() {
    return {
      show: false,
    };
  },
  created() {},
  methods: {
    showPopuo() {
      this.show = !this.show;
    },
  },
};
</script>
<style lang='scss' scoped>
@import "@styles/variables.scss";
.protocolPopup {
  width: 100%;
  height: 100%;
  padding: 0 8px;
  .mian{
    height: calc(100% - 72px);
  }
  .footer {
    margin-top: 18px;
    margin-bottom: 18px;
    width: 100%;
    height: 36px;
    line-height: 36px;
    text-align: center;
    background: #3e73fb;
    border-radius: 18px;
    color: #ffffff;
    font-size: 16px;
  }
}
</style>