<template>
  <div class="mallDetails">
    <div class="content">
      <overflowYHidden>
        <div class="swipe">
          <van-swipe
            height="280"
            :show-indicators="false"
            @change="swipeChange"
          >
            <van-swipe-item v-for="item in photos" :key="item.id">
              <img class="swipeImg" :src="$replacePhoto(item.url)" alt="" />
            </van-swipe-item>
          </van-swipe>
          <div class="swipeIndex">{{ swipeIndex + 1 }}/{{ photos.length }}</div>
        </div>
        <div class="header">
          <div class="title">
            <span class="old"> {{type}} </span
            ><span class="name">{{ detailsData.name }}</span>
          </div>
          <div class="cost">
            <span class="price"
              >¥{{ detailsData.sharePrice }}/{{
                formatBillingType(detailsData.billingType)
              }}<el-tooltip
                placement="top-start"
                v-if="detailsData.billingType == 0"
              >
                <div slot="content">1次使用期限为24小时<br />请按时归还</div>
                <van-icon name="warning-o" class="warning" /> </el-tooltip
            ></span>
          </div>
          <div class="accounts">
            <span class="price"> 仪器价格 {{ detailsData.price }}元</span>
            <van-rate
              v-model="detailsData.levelNum"
              :size="14"
              color="#ffd21e"
              void-icon="star"
              void-color="#eee"
              readonly
            />
          </div>
        </div>
        <div class="cell">
          <van-cell-group>
            <van-cell
              title="选择"
              value-class="cellValue"
              title-class="cellTitle"
              :border="false"
              :value="borrowCellText"
              is-link
              @click="showBorrowPopup"
            />
            <van-cell
              title="取件"
              value-class="cellValue"
              title-class="cellTitle"
              :border="false"
              value="自提"
            />
            <van-cell
              title="参数"
              value-class="cellValue"
              title-class="cellTitle"
              :border="false"
              :value="
                detailsData.brandName +
                '/' +
                detailsData.modelName +
                '/' +
                detailsData.code
              "
              @click="showKeyParamPopup"
              is-link
            />
            <!-- <van-cell
              title="租赁协议"
              value-class="cellValue"
              title-class="cellTitle"
              :border="false"
              value="查看协议"
              @click="showProtocolPopup"
              is-link
            /> -->
          </van-cell-group>
        </div>
      </overflowYHidden>
    </div>
    <van-button class="footer" @click="reservation" :loading="loading"
      >预约锁定</van-button
    >
    <borrowPopup
      ref="borrowPopup"
      :detailsData="detailsData"
      @borrowSubmit="borrowSubmit"
    ></borrowPopup>
    <keyParamPopup
      ref="keyParamPopup"
      :keyList="keyList"
      :detailsData="detailsData"
    ></keyParamPopup>
    <protocolPopup ref="protocolPopup"></protocolPopup>
  </div>
</template>

<script>
import borrowPopup from "./popup/borrowPopup";
import keyParamPopup from "./popup/keyParamPopup";
import protocolPopup from "./popup/protocolPopup";
import overflowYHidden from "@/components/overflowYHidden";
export default {
  components: {
    borrowPopup,
    keyParamPopup,
    protocolPopup,
    overflowYHidden,
  },
  data() {
    return {
      swipeIndex: 0,
      value: 5,
      detailsData: {
        levelNum:1,
      },
      keyList: [
        { key: "brandName", name: "品 牌", space: "21px", float: "right" },
        { key: "modelName", name: "型 号", space: "21px", float: "right" },
        { key: "code", name: this.$VUEAPPDEVICECODE(), space: "21px", float: "right" },
        { key: "label", name: "卡 片 号", space: "3px", float: "right" },
        {
          key: "categoryName",
          name: "设备分类",
          space: "21px",
          float: "right",
        },
      ],
      baseData: {
        levelNum:1,
        startTime: "",
        endTime: "",
        hour: "",
      },
      borrowCellText: "请选择借用信息",
      loading: false,
    };
  },
  computed: {
    type() {
      let msg = this.$getDataValue(this.detailsData, 'type', '');
      if (msg == '') {
        return '-'
      }
      return  msg + '类'
    },
    photos() {
      if (this.detailsData.photos && this.detailsData.photos.length > 0) {
        return this.detailsData.photos;
      } else {
        return [
          {
            url: require("@assets/images/noPhoto.jpg"),
            id: "noPhoto",
          },
        ];
      }
    },
  },
  created() {
    this.id = this.$route.query.id;
  },
  mounted() {
    this.shareDeviceDetail();
  },
  methods: {
    shareDeviceDetail() {
      this.$showLoading({
        target: ".mallDetails",
      });
      this.$api.deviceBorrowModule
        .shareDeviceDetail({
          deviceId: this.id,
        }).then((res) => {
          this.detailsData = res.data;
          let levelNum = 1;
          if (this.$valueIsExist(this.$route.query, 'levelNum')) {
            levelNum = this.$route.query['levelNum']
          }
          this.detailsData.levelNum = parseInt(levelNum);
          this.$hideLoading({
            target: ".mallDetails",
          });
        }).catch((e) => {
          this.$hideLoading({
            target: ".mallDetails",
          });
        });
    },
    swipeChange(index) {
      this.swipeIndex = index;
    },
    showBorrowPopup() {
      this.$refs.borrowPopup.showPopuo();
    },
    showKeyParamPopup() {
      this.$refs.keyParamPopup.showPopuo();
    },
    showProtocolPopup() {
      this.$refs.protocolPopup.showPopuo();
    },
    reservation() {
      if (!this.baseData.startTime || !this.baseData.endTime) {
        this.$toast.fail("请选择借用信息");
        this.showBorrowPopup();
      } else {
        this.sureAction();
      }
    },
    sureAction() {
      this.$dialog
        .confirm({
          message: `${this.detailsData.name}[预约申请]`,
        })
        .then(() => {
          this.loading = true;
          this.$api.deviceBorrowModule
            .shareBook({
              deviceId: this.id,
              startTime: this.baseData.startTime,
              endTime: this.baseData.endTime,
            })
            .then((res) => {
              this.loading = false;
              this.$toast.success("设备预约成功");
              localStorage.setItem('allDeviceUpdate', new Date().getTime())
              this.$back();
            })
            .catch((e) => {
              this.loading = false;
              this.$toast.fail(e.data.msg);
            });
        })
        .catch(() => {});
    },
    borrowSubmit(res) {
      this.baseData = res;
      this.borrowCellText = `${this.$formatMonthDay(
        this.baseData.startTime
      )}/${this.$formatMonthDay(this.baseData.endTime)}/${
        this.baseData.hour
      }小时`;
    },
  },
};
</script>
<style lang='scss' scoped>
@import "@styles/variables.scss";
.mallDetails {
  height: 100%;
  .swipe {
    position: relative;
    .swipeImg {
      width: 100%;
      height: 100%;
    }
    .swipeIndex {
      position: absolute;
      right: 5px;
      bottom: 5px;
      padding: 2px 5px;
      font-size: 12px;
      color: #ffffff;
    }
  }
  .header {
    background: $background_color2;
    padding: 5px 10px;
    border-radius: 12px;
    margin: 10px;
    .title{
      font-weight: 700;
    }
    .old {
      display: inline-block;
      color: #ffffff;
      line-height: 24px;
      width: 44px;
      height: 24px;
      background: linear-gradient(310deg, #fba53e 0%, #ffc27b 100%);
      border-radius: 4px;
      text-align: center;
      margin-right: 6px;
    }
    .name {
      font-size: 16px;
      color: #333333;
      vertical-align: middle;
    }
    .cost {
      margin-top: 18px;
      .price {
        font-size: 20px;
        color: #fd000d;
        .warning {
          font-size: 15px;
          color: $color3;
          margin-left: 8px;
        }
      }
    }
    .accounts {
      display: flex;
      justify-content: space-between;
      .price {
        color: $color3;
        font-size: 12px;
      }
    }
  }
  .cell {
    margin: 0 10px;
    border-radius: 12px;
    overflow: hidden;
    .cellValue {
      text-align: left;
      flex: 3;
      padding-right: 14px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .cellTitle {
      flex: 1;
    }
  }
  .content {
    height: calc(100% - 62px);
  }
  .footer {
    height: 40px;
    width: calc(100% - 20px);
    line-height: 40px;
    text-align: center;
    background: #3e73fb;
    border-radius: 20px;
    color: #ffffff;
    font-size: 16px;
    margin: 0 10px;
    margin-top: 18px;
  }
}
::v-deep.van-cell {
  padding: 8px 16px 8px 14px;
  position: relative;
}
::v-deep.van-cell__right-icon {
  position: absolute;
  top: 8px;
  right: 16px;
}
</style>